const data = [
    {
        id: 'provider',
        name: 'Boletos enviados ao provedor'
    },
    {
        id: 'subscriber',
        name: 'Boletos enviados ao assinante'
    }
];

export default [...data];