<template>
  <v-container>
    <v-row>
      <v-col :cols="12">
        <span class="text-h5">Detalhe do provedor</span>
      </v-col>
    </v-row>

    <ButtonBar>
      <v-btn type="button" color="primary" outlined @click="goBack"
        >Voltar</v-btn
      >
      <v-btn
        type="button"
        color="error"
        @click="remove"
        v-if="!isNew"
        v-auth="'PROVIDER.REMOVE'"
        :loading="removeButton.loading"
        >Excluir</v-btn
      >
      <v-btn
        type="button"
        color="primary"
        @click="update"
        v-if="!isNew"
        v-auth="'PROVIDER.UPDATE'"
        :loading="updateButton.loading"
        >Alterar</v-btn
      >
      <v-btn
        type="button"
        color="primary"
        @click="add"
        v-if="isNew"
        v-auth="'PROVIDER.ADD'"
        :loading="addButton.loading"
        >Adicionar</v-btn
      >
    </ButtonBar>

    <v-form ref="form" v-model="validForm">
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title class="card-title">Dados básicos</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-text-field
                    label="Razão social"
                    maxlength="60"
                    v-model="provider.name"
                    required
                    :rules="[$rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field
                    label="Nome fantasia"
                    maxlength="60"
                    v-model="provider.trading_name"
                    required
                    :rules="[$rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field
                    label="CNPJ"
                    maxlength="20"
                    v-model="provider.cpf_cnpj"
                    v-mask="$masks.cnpj"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-select
                    :items="data.providerStatus"
                    label="Status"
                    v-model="provider.status"
                    required
                    :rules="[$rules.required]"
                    item-value="id"
                    item-text="name"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="3">
                  <v-text-field label="IE" maxlength="20" v-model="provider.ie">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                  <date-field
                    label="Data do contrato"
                    v-model="provider.contract_date"
                  />
                </v-col>
                <v-col cols="12" sm="2">
                  <v-select
                    :items="data.providerTypes"
                    label="Tipo"
                    v-model="provider.type"
                    required
                    :rules="[$rules.required]"
                    item-value="id"
                    item-text="name"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="2" v-if="provider.type === 'isp'">
                  <v-checkbox v-model="provider.template">
                    <template v-slot:label>
                      <div>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">ISP template </span>
                          </template>
                          Define o ISP quer servirá como template para as URLs do Flussonic
                        </v-tooltip>
                      </div>
                    </template></v-checkbox
                  >
                </v-col>
                <v-col cols="12" sm="2" v-if="provider.type === 'isp'">
                  <v-checkbox v-model="provider.main" readonly>
                    <template v-slot:label>
                      <div>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">ISP principal </span>
                          </template>
                          Mantém o ISP como seleção padrão no combo de ISPs
                        </v-tooltip>
                      </div>
                    </template></v-checkbox
                  >
                </v-col>
                <v-col cols="12" sm="2" v-if="provider.type === 'isp'">
                  <v-text-field
                      label="SLUG (Identificador DGO)"
                      maxlength="40"
                      v-model="provider.slug"
                      required
                    ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    background-color="grey lighten-4"
                    label="Comentários"
                    rows="3"
                    v-model="provider.comments"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title class="card-title">Endereço</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="4" sm="3">
                  <v-text-field
                    label="CEP"
                    maxlength="9"
                    v-mask="$masks.zipCode"
                    v-model="provider.address.zip_code"
                    @change="lookupAddress"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="Endereço"
                    maxlength="120"
                    v-model="provider.address.street"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col :cols="4">
                  <v-text-field
                    label="Número"
                    v-model="provider.address.number"
                    maxlength="10"
                  ></v-text-field>
                </v-col>
                <v-col :cols="8">
                  <v-text-field
                    label="Complemento"
                    v-model="provider.address.street2"
                    maxlength="30"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-text-field
                    label="Bairro"
                    maxlength="40"
                    v-model="provider.address.district"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    label="Cidade"
                    maxlength="60"
                    v-model="provider.address.city"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                    :items="data.states"
                    label="Estado"
                    v-model="provider.address.state"
                    item-value="id"
                    item-text="name"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title class="card-title">Dados de contato</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-text-field
                    label="Nome do contato"
                    maxlength="60"
                    v-model="provider.contact_name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    label="Telefone do contato"
                    maxlength="60"
                    v-model="provider.contact_phone"
                    v-mask="$masks.phone"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    label="E-mail do contato"
                    maxlength="60"
                    v-model="provider.contact_email"
                    :rules="[$rules.email]"
                    type="email"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="provider.type === 'isp'">
        <v-col cols="12">
          <v-card>
            <v-card-title class="card-title">Dados da API</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-text-field
                    label="URL API"
                    maxlength="60"
                    v-model="provider.api_url"
                    :required="provider.type === 'isp'"
                    type="url"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field
                    label="Login API"
                    maxlength="100"
                    :required="provider.type === 'isp'"
                    v-model="provider.api_username"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field
                    label="Senha API"
                    maxlength="60"
                    :required="provider.type === 'isp'"
                    v-model="provider.api_password"
                    type="password"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="1">
                  <v-select
                    :items="api_versions"
                    label="Versão API"
                    :required="provider.type === 'isp'"
                    v-model="provider.api_version"
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { eventHub } from "@/main";
import addressApi from "@/common/api/address.api";
import providerApi from "@/common/api/provider.api";
import providerStatus from "@/common/data/provider-status.data";
import states from "@/common/data/states.data";
import providerTypes from "@/common/data/provider-types.data";
import providerPaymentModes from "@/common/data/provider-payment-modes.data";
import toaster from "@/common/util/toaster";
import ButtonBar from "@/components/layout/ButtonBar";
import DateField from "@/components/DateField.vue";

export default {
  name: "ProviderDetails",

  components: { ButtonBar, DateField },

  data() {
    return {
      data: {
        states: states,
        providerTypes: [{ id: "", name: "Selecione" }, ...providerTypes],
        providerStatus: [{ id: "", name: "Selecione" }, ...providerStatus],
        providerPaymentModes: [
          { id: "", name: "Selecione" },
          ...providerPaymentModes,
        ],
      },
      validForm: true,
      isNew: true,
      provider: {
        provider_type: "",
        address: {
          street: "",
        },
        status: "A",
      },
      removeButton: {
        loading: false,
      },
      updateButton: {
        loading: false,
      },
      addButton: {
        loading: false,
      },
      api_versions: [1, 2]
    };
  },

  created() {
    this.load(this.$route.params.id);
  },

  methods: {
    load(providerId) {
      if (providerId !== "novo") {
        this.isNew = false;

        providerApi.get(providerId).then((result) => {
          this.provider = result.data;
        });
      }
    },

    lookupAddress() {
      addressApi.lookup(this.provider.address.zip_code).then((result) => {
        if (result.data) {
          this.provider.address.street = result.data.street;
          this.provider.address.district = result.data.district;
          this.provider.address.city = result.data.city;
          this.provider.address.state = result.data.state;
        }
      });
    },

    goBack() {
      this.$router.replace("/provedores");
    },

    async add() {
      this.$refs.form.validate();

      if (await this.$root.$confirm(this.validForm, "provider.add")) {
        let data = {
          name: this.provider.name,
          trading_name: this.provider.trading_name,
          cpf_cnpj: this.provider.cpf_cnpj,
          ie: this.provider.ie,
          main: !!this.provider.main,
          template: this.provider.template,
          address: this.provider.address,
          api_url: this.provider.api_url,
          api_username: this.provider.api_username,
          api_password: this.provider.api_password,
          api_version: this.provider.api_version,
          contact_name: this.provider.contact_name,
          contact_phone: this.provider.contact_phone,
          contact_email: this.provider.contact_email,
          contract_date: this.provider.contract_date,
          comments: this.provider.comments,
          type: this.provider.type,
          status: this.provider.status,
          urns: this.provider.urns,
          slug: this.provider.slug,
        };

        this.addButton.loading = true;

        providerApi
          .add(data)
          .then(() => {
            this.addButton.loading = false;
            toaster.show("Provedor adicionado com sucesso!");
            eventHub.$emit("REFRESH_CLIENTS");
            this.goBack();
          })
          .catch(() => (this.addButton.loading = false));
      }
    },

    async update() {
      this.$refs.form.validate();

      if (await this.$root.$confirm(this.validForm, "provider.update")) {
        let data = {
          name: this.provider.name,
          trading_name: this.provider.trading_name,
          cpf_cnpj: this.provider.cpf_cnpj,
          ie: this.provider.ie,
          main: !!this.provider.main,
          template: this.provider.template,
          address_id: this.provider.address_id,
          address: this.provider.address,
          api_url: this.provider.api_url,
          api_username: this.provider.api_username,
          api_password: this.provider.api_password,
          api_version: this.provider.api_version,
          contact_name: this.provider.contact_name,
          contact_phone: this.provider.contact_phone,
          contact_email: this.provider.contact_email,
          contract_date: this.provider.contract_date,
          comments: this.provider.comments,
          type: this.provider.type,
          status: this.provider.status,
          urns: this.provider.urns,
          slug: this.provider.slug,
        };

        this.updateButton.loading = true;

        providerApi
          .update(this.provider.id, data)
          .then(() => {
            this.updateButton.loading = false;
            toaster.show("Provedor alterado com sucesso!");
            eventHub.$emit("REFRESH_CLIENTS");
            this.goBack();
          })
          .catch(() => (this.updateButton.loading = false));
      }
    },

    async remove() {
      if (await this.$root.$confirm(true, "provider.remove")) {
        this.removeButton.loading = true;
        providerApi
          .remove(this.provider.id)
          .then(() => {
            this.removeButton.loading = false;

            toaster.show("Provedor excluído com sucesso!");
            eventHub.$emit("REFRESH_CLIENTS");
            this.goBack();
          })
          .catch(() => (this.removeButton.loading = false));
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
